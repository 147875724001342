import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cloudaeon-ssbia',
  templateUrl: './cloudaeon-ssbia.component.html',
  styleUrls: ['./cloudaeon-ssbia.component.scss']
})
export class CloudaeonSSBIAComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToHome() {
    this.router.navigate(['/home']); // Replace '/dashboard' with your desired route
  }

}
