import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router } from '@angular/router';
@Component({
  selector: 'app-webtemplate',
  templateUrl: './webtemplate.component.html',
  styleUrls: ['./webtemplate.component.scss']
})
export class WebtemplateComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    $(document).ready(() => {
      $('body').addClass('balcklogo');
      var loc = window.location.href; // returns the full URL
      console.log(loc);
      if (/icon/.test(loc)) {
        $('body').addClass('tech');
        $('body').removeClass('Applications');
        $('body').removeClass('about-bg');
      }

            // scroling top
      $('a.linkHead').click(function(e) {
        $('html,body').animate({ scrollTop:top}, 200);
        return false;
        e.preventDefault();
      });
      var windowsMinhight = $(window).height();

      $(".side-bannertab-item").click(function(){
        console.log($(this).hasClass("Applications"))
        if($(this).hasClass("Applications")){
          $('.Applications').addClass('active');
          $('.MobileApps').removeClass('active');
          $('.EcommerceWebSites').removeClass('active');
          $('.WebsitesPortfolio').removeClass('active');
        }
        if ($(this).hasClass("MobileApps")){
          $('.MobileApps').addClass('active');
          $('.EcommerceWebSites').removeClass('active');
          $('.WebsitesPortfolio').removeClass('active');
          $('.Applications').removeClass('active');
        }
        if ($(this).hasClass("EcommerceWebSites")){
          $('.EcommerceWebSites').addClass('active');
          $('.MobileApps').removeClass('active');
          $('.WebsitesPortfolio').removeClass('active');
          $('.Applications').removeClass('active');
        }
        if ($(this).hasClass("WebsitesPortfolio")){
          $('.WebsitesPortfolio').addClass('active');
          $('.MobileApps').removeClass('active');
          $('.EcommerceWebSites').removeClass('active');
          $('.Applications').removeClass('active');
        }
      });

      $(window).scroll(function() {
        if ($(this).scrollTop()>250)
         {
            $('.side-bannertab-item').addClass('colorchange');
         }
        else
         {
          $('.side-bannertab-item').removeClass('colorchange');
         };

         if($(this).scrollTop()>0){
          $('.Applications').addClass('active');
          $('.MobileApps').removeClass('active');
          $('.EcommerceWebSites').removeClass('active');
          $('.WebsitesPortfolio').removeClass('active');
        }
        if ($(this).scrollTop()> windowsMinhight + 200){
          $('.MobileApps').addClass('active');
          $('.EcommerceWebSites').removeClass('active');
          $('.WebsitesPortfolio').removeClass('active');
          $('.Applications').removeClass('active');
        }
        if ($(this).scrollTop()>windowsMinhight * 3 ){
          $('.EcommerceWebSites').addClass('active');
          $('.MobileApps').removeClass('active');
          $('.WebsitesPortfolio').removeClass('active');
          $('.Applications').removeClass('active');
        }
        if ($(this).scrollTop()>windowsMinhight * 4){
          $('.WebsitesPortfolio').addClass('active');
          $('.MobileApps').removeClass('active');
          $('.EcommerceWebSites').removeClass('active');
          $('.Applications').removeClass('active');
        }
      });
    })
  }

  navigateToHome() {
    this.router.navigate(['/home']); // Replace '/dashboard' with your desired route
  }

}
