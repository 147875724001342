<div class="ak-bradcrame pb-2">
  <div class="container">
    <div class="row">
      <div class="col-6" >
        <div class="wm-b-h wm-g2">
          <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last" [class.active]="last">
            <ng-container *ngIf="!last">
              <a class="ak-link-home wm-subt1 ak-link" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
              <span class="seprater wm-subt1"> 
                <img src="./../../assets/svg/VectorLight.svg" alt="arrow right">
              </span>
            </ng-container>
            <ng-container *ngIf="last">
              <span class="ak-link-home wm-subt1">{{ breadcrumb.label }}</span>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        
      </div>
    </div>
  </div>
</div>
