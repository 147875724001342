<section class="header">
  <div class="banner-content">
    <div class="header-title text-center wm-b-v wm-g3">
      <a class="templateHome_icon-inner">
        <svg width="81" height="56" viewBox="0 0 81 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2641_1764" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="81" height="56">
          <path d="M80.5 0H0.5V55.1724H80.5V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_2641_1764)">
          <mask id="mask1_2641_1764" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="28" y="0" width="24" height="37">
          <path d="M28.542 0H51.886V36.7589H28.542V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask1_2641_1764)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9197 36.6615L51.6143 0.0248692L28.5522 0L39.9197 36.6615Z" fill="#323130" fill-opacity="0.87"/>
          </g>
          <mask id="mask2_2641_1764" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="15" y="24" width="19" height="32">
          <path d="M15.8091 24.5562H33.8475V55.1721H15.8091V24.5562Z" fill="white"/>
          </mask>
          <g mask="url(#mask2_2641_1764)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1533 24.7784C21.9409 26.6872 18.797 37.1551 17.8644 39.9964C16.2458 44.9225 15.5557 44.7796 16.5961 47.7184C17.5204 50.3211 18.2789 52.6651 19.1825 55.2287L33.4492 55.235C33.4076 54.0185 23.9532 25.8354 23.1533 24.7784Z" fill="#323130" fill-opacity="0.87"/>
          </g>
          <mask id="mask3_2641_1764" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="46" y="24" width="19" height="32">
          <path d="M46.5801 24.5562H64.6187V55.1721H46.5801V24.5562Z" fill="white"/>
          </mask>
          <g mask="url(#mask3_2641_1764)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M61.3069 55.1142C62.7969 47.7633 65.2589 48.0805 63.6487 43.5335C62.2645 39.6165 58.3765 26.6535 57.1847 24.8235C54.3828 31.0596 49.9458 47.3363 46.9243 55.0833L61.3069 55.1142Z" fill="#323130" fill-opacity="0.87"/>
          </g>
          <mask id="mask4_2641_1764" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="26">
          <path d="M0.5 0H17.9313V25.0868H0.5V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask4_2641_1764)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.97832 24.5998L17.4422 0.0455931L0.57666 0C0.933121 2.30455 5.34118 14.3744 6.65925 18.281L8.84568 24.376C8.8747 24.4299 8.93065 24.5273 8.97832 24.5998Z" fill="#323130" fill-opacity="0.87"/>
          </g>
          <mask id="mask5_2641_1764" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="62" y="0" width="18" height="26">
          <path d="M62.4976 0H79.8873V25.0868H62.4976V0Z" fill="white"/>
          </mask>
          <g mask="url(#mask5_2641_1764)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M71.2102 24.6328L75.664 12.4013C76.669 9.45433 79.3382 2.75415 79.7506 0.014391L62.9639 0.00402832L71.2102 24.6328Z" fill="#323130" fill-opacity="0.87"/>
          </g>
          </g>
        </svg>                            
      </a>
      <h2>Worxmate</h2>
      <div class="web-hader-title">
        Worxmate is workforce management solutions.
      </div>
      <div class="wm-subt1 ak-web-hader-sutitle">
        Led UX studies and prototyping for the "Worxmate" OKR application, integrating Angular components with HTML, CSS, JavaScript, and TypeScript to deliver a seamless user experience.
      </div>
    </div>
  </div>
</section>

<!-- <div class="ak-bradcrame mb-3">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="wm-b-h wm-g2">
          <span class="ak-link-home wm-subt1 ak-link" (click)="navigateToHome()">Home</span>
          <span class="seprater wm-subt1"> 
            <img src="./../../assets/svg/VectorLight.svg" alt="arrow right">
          </span>
          <span class="ak-link-home wm-subt1">My Work</span>
          <span class="seprater wm-subt1"> 
            <img src="./../../assets/svg/VectorLight.svg" alt="arrow right">
          </span>
          <span class="ak-link-home wm-subt1">Worxmate</span>
        </div>
      </div>
      <div class="col-6">
        
      </div>
    </div>
  </div>
</div> -->

<app-breadcrumb [breadcrumbs]="[
    { label: 'Home', url: '/home' },
    { label: 'My Work', url: '/mywork' },
    { label: 'Worxmate', url: '/worxmeet' }
  ]"
></app-breadcrumb>

<section class="ak-Uiconsept-box mb-4 mt-2">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Color</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Fcolor-wor.svg?alt=media&token=cf19e862-a551-4fc6-a58b-f330aafb25e0" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Typography</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Ftypography-ws.svg?alt=media&token=e7601419-1b2d-4563-9c7d-3ad44bf667ab" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Elevation</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Felevation-ws.svg?alt=media&token=c21f4fab-85b4-4d8d-9ced-d82ada99289e" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Icon</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Ficon-ws.svg?alt=media&token=0110f53d-23f9-4e9e-97e3-5206e950676b" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">UI Components</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Fuicomponents-ws.svg?alt=media&token=4aa38ddb-cde9-484d-9023-dfaf9a702910" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">User Avatars</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Fuser-avatars.svg?alt=media&token=f20098e3-74d5-4c3d-99e0-a0df3e35e150" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Worxmate Screen 1</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Fworxmate-screen0.svg?alt=media&token=e9a79b3a-d452-413b-aeab-445ac930966d" alt="color" width="100%">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Worxmate Screen 2</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Fworxmate-screen1.svg?alt=media&token=1de5aca2-bde8-440c-ac9b-68d89b2d140e" alt="color" width="100%">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Worxmate Screen 3</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2FWorxmate%20Fworxmeat-screen-3.svg?alt=media&token=d1d9a7cb-11ea-4e8a-a801-328348298438" alt="color" width="100%">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Worxmate Screen 4</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2FWorxmate%20Meeting.svg?alt=media&token=4d4fc377-0c1c-41cd-ad61-b868814cf09a" alt="color" width="100%">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5 mb-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Worxmate Screen 5</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FWorxmate%2Fworxmeet-screen4.svg?alt=media&token=874edb57-2fe6-4e91-b60f-a43a6e210bdc" alt="color" width="100%">
    </div>
  </div>
</section>