<section class="header">
  <div class="banner-content">
    <div class="header-title text-center wm-b-v wm-g3">
      <a class="templateHome_icon-inner">
       <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2Fimage%2067.png?alt=media&token=7536c35b-ebf0-49a5-ac0d-d00137dc367e"   />                       
      </a>
      <h2>Cloudaeon SSBI</h2>
      <div class="web-hader-title">
       AI-powered big data analytics platform
      </div>
      <div class="wm-subt1 ak-web-hader-sutitle">
        Designed and developed AI-powered big data analytics platform, leveraging insights from prior AI application experience to optimize user workflows Created custom SVG data visualization components using React and Node.js, improving data comprehension rates by 40% Implemented responsive dashboard designs that reduced complex data analysis time by 50%, receiving commendation from stakeholders Established consistent design system across analytics interfaces, increasing development efficiency by 35%Successfully translated complex big data concepts into intuitive user interfaces, resulting in 90% positive client feedback and 45% faster user onboarding
      </div>
    </div>
  </div>
</section>

<!-- <div class="ak-bradcrame mb-3">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="wm-b-h wm-g2">
          <span class="ak-link-home wm-subt1 ak-link" (click)="navigateToHome()">Home</span>
          <span class="seprater wm-subt1"> 
            <img src="./../../assets/svg/VectorLight.svg" alt="arrow right">
          </span>
          <span class="ak-link-home wm-subt1">My Work</span>
          <span class="seprater wm-subt1"> 
            <img src="./../../assets/svg/VectorLight.svg" alt="arrow right">
          </span>
          <span class="ak-link-home wm-subt1">Worxmate</span>
        </div>
      </div>
      <div class="col-6">
        
      </div>
    </div>
  </div>
</div> -->

<app-breadcrumb [breadcrumbs]="[
    { label: 'Home', url: '/home' },
    { label: 'My Work', url: '/mywork' },
    { label: 'Worxmate', url: '/worxmeet' }
  ]"
></app-breadcrumb>

<section class="ak-Uiconsept-box mb-4 mt-2">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Color</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2FColors.svg?alt=media&token=4ff70470-83b0-4403-a2fa-74629f68231b" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Typography</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2FTypography.svg?alt=media&token=a49a892e-bf3a-4c80-a1fc-2a899ca2a46a" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">Icon</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2FIcon.svg?alt=media&token=0c9e24c6-1701-4b9c-85f8-72a6731a308d" alt="color">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">UI Components</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2FUI%20Components.svg?alt=media&token=16956f44-9f2c-4fc8-b101-ce57d18a2be1" alt="color">
    </div>
  </div>
</section>


<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">CloudaeonSSBI Screen 1</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2FCloudaeonSSBI%20AI.svg?alt=media&token=d7f02f7d-9b4c-49fb-8f80-0e2b12ee5247" alt="color" width="100%">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5"> 
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">CloudaeonSSBI Screen 2</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2FCloudeaonSSBI%202.svg?alt=media&token=2b79be10-0adf-44e4-9ff7-5b40f131dd84" alt="color" width="100%">
    </div>
  </div>
</section>

<section class="ak-Uiconsept-box mt-5">
  <div class="ak-Uiconsept-box-Wrapper wm-b-v wm-g3">
    <div class="wm-t1">CloudaeonSSBI Screen 3</div>
    <div class="ak-Uiconsept-box-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/annasahebkute-978c4.appspot.com/o/webtemplate%2FApplications%2FCloudaeonSSBI%20AI%2FCloudeaonSSBI%203.svg?alt=media&token=c50d5531-d7a4-424b-9afb-a90871ac6c94" alt="color" width="100%">
    </div>
  </div>
</section>
