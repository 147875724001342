import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-worxmate',
  templateUrl: './worxmate.component.html',
  styleUrls: ['./worxmate.component.scss']
})
export class WorxmateComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToHome() {
    this.router.navigate(['/home']); // Replace '/dashboard' with your desired route
  }

}
