import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { IconComponent } from './icon/icon.component';
import { PaintingComponent } from './painting/painting.component';
import { WebtemplateComponent } from './webtemplate/webtemplate.component';
import { SkillComponent } from './skill/skill.component';
import { PhotogarphComponent } from './photogarph/photogarph.component';
import { BlogsComponent } from './blogs/blogs.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { GanpatiFestivalComponent } from './photogarph/ganpati-festival/ganpati-festival.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FlowersComponent } from './photogarph/flowers/flowers.component';
import { BirdsComponent } from './photogarph/birds/birds.component';
import { GodlampComponent } from './photogarph/godlamp/godlamp.component';
import { HomeAlltabComponent } from './home/home-alltab/home-alltab.component';
import { HomeIconSectionComponent } from './home/home-icon-section/home-icon-section.component';
import { HomePaintingSectionComponent } from './home/home-painting-section/home-painting-section.component';
import { HomeTemplatesSectionComponent } from './home/home-templates-section/home-templates-section.component';
import { HomePhotographySectionComponent } from './home/home-photography-section/home-photography-section.component';
import { HeaderinnerComponent } from './headerinner/headerinner.component';
import { BestThreeTemplatesComponent } from './webtemplate/best-three-templates/best-three-templates.component';
import { TemplatesBannerComponent } from './webtemplate/templates-banner/templates-banner.component';
import { RecentTemplatesWorkComponent } from './webtemplate/recent-templates-work/recent-templates-work.component';
import { ApplicationsTemplatesComponent } from './webtemplate/applications-templates/applications-templates.component';
import { EcommerceWebSitesTemplatesComponent } from './webtemplate/ecommerce-web-sites-templates/ecommerce-web-sites-templates.component';
import { WebsitesPortfolioTemplatesComponent } from './webtemplate/websites-portfolio-templates/websites-portfolio-templates.component';
import { MobileAppsTemplatesComponent } from './webtemplate/mobile-apps-templates/mobile-apps-templates.component';
import { HomeWebtemplatesComponent } from './home/home-webtemplates/home-webtemplates.component';
import { EquinixComponent } from './webtemplate/applications-templates/equinix/equinix.component';
import { MiInternalJobportalComponent } from './webtemplate/applications-templates/mi-internal-jobportal/mi-internal-jobportal.component';
import { SmartHandComponent } from './webtemplate/applications-templates/smart-hand/smart-hand.component';
import { MarketSightsComponent } from './webtemplate/applications-templates/market-sights/market-sights.component';
import { InsetsComponent } from './photogarph/insets/insets.component';
import { AnimalsComponent } from './photogarph/animals/animals.component';
import { SuperDComponent } from './webtemplate/applications-templates/super-d/super-d.component';
import { GwsComponent } from './webtemplate/applications-templates/gws/gws.component';
import { SummitSolutionComponent } from './webtemplate/mobile-apps-templates/summit-solution/summit-solution.component';
import { ShallComponent } from './webtemplate/mobile-apps-templates/shall/shall.component';
import { YaseerMortgageServicesComponent } from './webtemplate/mobile-apps-templates/yaseer-mortgage-services/yaseer-mortgage-services.component';
import { CallaldaleelComponent } from './webtemplate/mobile-apps-templates/callaldaleel/callaldaleel.component';
import { WorxmateComponent } from './webtemplate/applications-templates/worxmate/worxmate.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CloudaeonSSBIAComponent } from './webtemplate/applications-templates/cloudaeon-ssbia/cloudaeon-ssbia.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    IconComponent,
    PaintingComponent,
    WebtemplateComponent,
    SkillComponent,
    PhotogarphComponent,
    BlogsComponent,
    AboutusComponent,
    GanpatiFestivalComponent,
    HeaderComponent,
    FooterComponent,
    FlowersComponent,
    BirdsComponent,
    GodlampComponent,
    HomeAlltabComponent,
    HomeIconSectionComponent,
    HomePaintingSectionComponent,
    HomeTemplatesSectionComponent,
    HomePhotographySectionComponent,
    HeaderinnerComponent,
    BestThreeTemplatesComponent,
    TemplatesBannerComponent,
    RecentTemplatesWorkComponent,
    ApplicationsTemplatesComponent,
    EcommerceWebSitesTemplatesComponent,
    WebsitesPortfolioTemplatesComponent,
    MobileAppsTemplatesComponent,
    HomeWebtemplatesComponent,
    EquinixComponent,
    MiInternalJobportalComponent,
    SmartHandComponent,
    MarketSightsComponent,
    InsetsComponent,
    AnimalsComponent,
    SuperDComponent,
    GwsComponent,
    SummitSolutionComponent,
    ShallComponent,
    YaseerMortgageServicesComponent,
    CallaldaleelComponent,
    WorxmateComponent,
    BreadcrumbComponent,
    CloudaeonSSBIAComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
