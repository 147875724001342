import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { IconComponent } from './icon/icon.component';
import { PaintingComponent } from './painting/painting.component';
import { WebtemplateComponent } from './webtemplate/webtemplate.component';
import { PhotogarphComponent } from './photogarph/photogarph.component';
import { BlogsComponent } from './blogs/blogs.component'
import { AboutusComponent } from './aboutus/aboutus.component'
import { GanpatiFestivalComponent } from './photogarph/ganpati-festival/ganpati-festival.component';
import { FlowersComponent } from './photogarph/flowers/flowers.component';
import { BirdsComponent } from './photogarph/birds/birds.component';
import { GodlampComponent } from './photogarph/godlamp/godlamp.component';
import { EquinixComponent } from "./webtemplate/applications-templates/equinix/equinix.component";
import { MarketSightsComponent } from "./webtemplate/applications-templates/market-sights/market-sights.component";
import { MiInternalJobportalComponent } from "./webtemplate/applications-templates/mi-internal-jobportal/mi-internal-jobportal.component";
import { SmartHandComponent } from "./webtemplate/applications-templates/smart-hand/smart-hand.component";
import { InsetsComponent } from './photogarph/insets/insets.component';
import { AnimalsComponent } from './photogarph/animals/animals.component';
import { SuperDComponent } from "./webtemplate/applications-templates/super-d/super-d.component";
import { GwsComponent } from "./webtemplate/applications-templates/gws/gws.component";
import { ShallComponent } from "./webtemplate/mobile-apps-templates/shall/shall.component";
import { SummitSolutionComponent } from "./webtemplate/mobile-apps-templates/summit-solution/summit-solution.component";
import { CallaldaleelComponent } from "./webtemplate/mobile-apps-templates/callaldaleel/callaldaleel.component";
import { YaseerMortgageServicesComponent } from "./webtemplate/mobile-apps-templates/yaseer-mortgage-services/yaseer-mortgage-services.component";
import { WorxmateComponent } from "./webtemplate/applications-templates/worxmate/worxmate.component"
import { CloudaeonSSBIAComponent } from "./webtemplate/applications-templates/cloudaeon-ssbia/cloudaeon-ssbia.component"
const routes: Routes = [
   { path: 'home',  component: HomeComponent },
   { path: 'aboutme',  component: AboutusComponent },
   { path: 'icon',  component: IconComponent },
   { path: 'painting',  component: PaintingComponent },

   //webtemplate links
   { path: 'mywork',  component: WebtemplateComponent },
    //Application
    { path: 'worxmeet',  component: WorxmateComponent },
    { path: 'cloudeaonssbia',  component: CloudaeonSSBIAComponent },
    { path: 'equinix',  component: EquinixComponent },
    { path: 'marketsights',  component: MarketSightsComponent },
    { path: 'miinternaljob',  component: MiInternalJobportalComponent },
    { path: 'smarthand',  component: SmartHandComponent },
    { path: 'superd',  component: SuperDComponent },
    { path: 'gws',  component: GwsComponent },
    //mobile application
    { path: 'shall',  component: ShallComponent },
    { path: 'summitsolution',  component: SummitSolutionComponent },
    { path: 'callaldaleel',  component: CallaldaleelComponent },
    { path: 'yaseermortgageservices',  component: YaseerMortgageServicesComponent },
    
   
    //photography
   { path: 'photography',  component: PhotogarphComponent },
   { path: 'ganpati-festival',  component: GanpatiFestivalComponent },
   { path: 'flowers',  component: FlowersComponent },
   { path: 'birds',  component: BirdsComponent },
   { path: 'godlamp',  component: GodlampComponent },
   { path: 'insets',  component: InsetsComponent },
   { path: 'animal',  component: AnimalsComponent },

   { path: 'blogs',  component: BlogsComponent },
   { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
